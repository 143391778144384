import config from '@/utility/config';

import publicAxiosRequest from '@/services/helpers/publicAxiosRequest';

const { loginApiRoute } = config;

/**
 * Payload should send either authToken or refreshToken
 * @param {*} payload | Object
 * @param {*} payload.authToken | String | For Social Logins
 * @param {*} payload.refreshToken | String
 * @returns Promise
 */
export const getTokensService = async (payload) => {
  try {
    const path = `${loginApiRoute}/api/v1/get-token`;
    return await publicAxiosRequest.post(path, payload, {
      withCredentials: true
    });
  } catch (e) {
    return { error: e.message };
  }
};
