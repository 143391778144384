import config from '@/utility/config';

import { getAxiosError, getAxiosErrorMsg } from '../helpers';
import adminProtectedAxiosRequest from '../helpers/adminProtectedAxiosRequest';

const { communityApiBasePath, apiPaymentBasePath } = config;

/**
 *
 * @param {payload} payload
 * @returns
 */
export const uploadBannerImage = async (payload, communityId) => {
  try {
    const data = await adminProtectedAxiosRequest.put(
      `${communityApiBasePath}/api/v1/communities/${communityId}/banner-image`,
      payload
    );

    return data;
  } catch (error) {
    return getAxiosError(error);
  }
};

export const uploadCommunityProfileImage = async (
  payload,
  communityId
) => {
  try {
    const data = await adminProtectedAxiosRequest.put(
      `${communityApiBasePath}/api/v1/communities/${communityId}/thumbnail-image`,
      payload
    );
    return data;
  } catch (error) {
    return getAxiosError(error);
  }
};

export const updateCommunity = async (payload, communityId) => {
  try {
    const { data, error } = await adminProtectedAxiosRequest.put(
      `${communityApiBasePath}/api/v1/communities/${communityId}`,
      payload
    );

    if (error) {
      return { data: null, error: getAxiosErrorMsg(error) };
    }
    return { data: data, error: null };
  } catch (error) {
    return { data: null, error: getAxiosErrorMsg(error) };
  }
};

export const getConnectedBotInformation = async (communityId) => {
  try {
    return await adminProtectedAxiosRequest.get(
      `${communityApiBasePath}/api/v1/communities/${communityId}/bots`
    );
  } catch (error) {
    return { error: getAxiosErrorMsg(error) };
  }
};

export const revalidateLandingPage = async ({ payload }) => {
  try {
    const path = `${apiPaymentBasePath}/api/v1/revalidate-fe-path`;
    const res = await adminProtectedAxiosRequest.post(path, payload);
    return { data: res.data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const revalidateCommunityLandingPage = async (community) => {
  const payload = {
    slug: community?.link
  };
  return await revalidateLandingPage({ payload });
  // try {
  //   const path = `${apiPaymentBasePath}/api/v1/revalidate-fe-path`;
  //   const res = await adminProtectedAxiosRequest.post(path, payload);
  //   return { data: res.data, error: null };
  // } catch (error) {
  //   return { error: error?.response?.data?.message ?? error.message };
  // }
};

export const getCommunityRoles = async (communityId, configured) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/discord-roles${
      configured ? '?configured=true' : ''
    }`;
    const res = await adminProtectedAxiosRequest.get(path);
    return { data: res.data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const updateRoleConfigurationForDiscord = async (
  roleId,
  communityId,
  payload
) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/discord-role-config/${roleId}`;
    const res = await adminProtectedAxiosRequest.put(path, payload);
    return { data: res.data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const disconnectChatBot = async (communityId, chatType) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/disconnect-chat`;
    const res = await adminProtectedAxiosRequest.post(path, { chatType });
    return { data: res.data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const getRoleConfigurationForDiscord = async () => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/discord-role-config?configured=true`;
    const res = await adminProtectedAxiosRequest.get(path);
    return { data: res.data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const getAllDiscountCoupons = async (
  communityCode,
  communityId
) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/list/discounts?communityCode=${communityCode}`;
    const res = await adminProtectedAxiosRequest.get(path);
    return { data: res.data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const updateDiscountCoupon = async (
  payload,
  discountId,
  communityId
) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/discounts/${discountId}`;
    const res = await adminProtectedAxiosRequest.put(path, payload);
    return { data: res.data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const deleteCommunityRole = async (roleId, communityId) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/discord-role-config/${roleId}`;
    const res = await adminProtectedAxiosRequest.delete(path);
    return { data: res.data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const getCommunityPricing = async (communityId) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/prices`;
    const res = await adminProtectedAxiosRequest.get(path);
    return { data: res.data, error: null };
  } catch (error) {
    return { error: getAxiosError(error) };
  }
};
