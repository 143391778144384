import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

import { routeListShowingChat } from '@/utility/constants';

import useFacebookChatIcon from '@/hooks/useFacebookChatIcon';

const useChatWidget = () => {
  const pathname = usePathname();

  const { showChatWidget, hideChatWidget } = useFacebookChatIcon();

  useEffect(() => {
    if (routeListShowingChat.includes(pathname)) {
      showChatWidget();
    } else {
      hideChatWidget();
    }
  }, [hideChatWidget, pathname, showChatWidget]);
};

export default useChatWidget;
