import React from 'react';

import { STATIC_ASSET_BASE_URL } from '@/utility/constants';
import { t } from '@/utility/localization';

const ErrorDisplay = ({
  title,
  description,
  imageURL,
  renderButton,
  renderFootNote,
  errorCode
}) => {
  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <div className="max-w-560 flex flex-col px-16 text-center">
        <div className="relative m-auto aspect-[2/1] px-16">
          <img src={imageURL} className="w-full max-w-[560px]" alt="404" />
        </div>
        <div className="mb-32 mt-24 space-y-8 ">
          <h1 className="text-colour-light-surface-primary text-heading-md font-semibold">
            {title}
          </h1>
          <p className="text-npl-text-icon-on-light-surface-secondary">
            {description && description()}
          </p>
        </div>
        {errorCode && (
          <div className="mb-32 text-overline-md font-medium text-npl-text-icon-on-light-surface-tertiary">
            {t('error-code-1:')} {errorCode}
          </div>
        )}
        <div className="mb-24 flex flex-col-reverse justify-center gap-12 sm:flex-row">
          {renderButton}
        </div>
        <div>{renderFootNote && renderFootNote()}</div>
      </div>
      <div className="absolute bottom-0 py-32 text-npl-transparent-black-25 ">
        <img
          src={`${STATIC_ASSET_BASE_URL}/nasIO/portal/png/Nasio%20Logo.svg`}
          width={82.5}
          height={18}
          alt="404"
        />
      </div>
    </div>
  );
};

export default ErrorDisplay;
