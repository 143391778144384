import React, { createContext, useContext, useState } from 'react';

import { removeElFromDomById } from '@/utility/domHelpers';

const getTwitterPixelScriptId = (twitterPixelId) =>
  `twitter-pixel-${twitterPixelId}`;

const getLinkedinInsightsPixelScriptId = (pixelId) =>
  `linkedin-insights-pixel-${pixelId}`;

// Create the PixelsContext
const PixelsContext = createContext();

// Create the PixelsContextProvider
const PixelsContextProvider = ({ children }) => {
  const [fbTrackingPixel, setFbTrackingPixel] = useState(null);
  const [tiktokTrackingPixel, setTiktokTrackingPixel] = useState(null);
  const [gaPixel, setGaPixel] = useState(null);
  const [twitterPixel, setTwitterPixel] = useState(null);
  const [linkedinInsigtsPixel, setLinkedinInsigtsPixel] = useState(null);

  // Meta Pixel
  const initFbTrackingPixel = (fbPixelId, callback) => {
    if (fbTrackingPixel?.pixel && fbTrackingPixel?.id === fbPixelId) {
      callback(fbTrackingPixel.pixel);
      return;
    }

    if (fbPixelId) {
      import('react-facebook-pixel')
        .then((fbPixel) => fbPixel.default)
        .then((fbPixel) => {
          // Initialize
          fbPixel.init(fbPixelId);
          return fbPixel;
        })
        .then((fbPixel) => {
          try {
            // Set state
            setFbTrackingPixel({
              id: fbPixelId,
              pixel: fbPixel
            });
            // Call callback if provided
            if (callback) {
              callback(fbPixel);
            }
          } catch (e) {
            console.warn('>> Callback to initFbTrackingPixel failed.', e);
          }
        });
    }
  };

  const clearFbTrackingPixel = () => setFbTrackingPixel(null);

  // Tiktok Pixel
  const initTiktokTrackingPixel = (tiktokPixelId, callback) => {
    if (
      tiktokTrackingPixel?.pixel &&
      tiktokTrackingPixel?.id === tiktokPixelId
    ) {
      callback(tiktokTrackingPixel.pixel);
      return;
    }

    if (tiktokPixelId) {
      import('tiktok-pixel')
        .then((pixel) => pixel.default)
        .then((pixel) => {
          // Initialize
          pixel.init(tiktokPixelId);
          return pixel;
        })
        .then((pixel) => {
          try {
            // Set state
            setTiktokTrackingPixel({
              id: tiktokPixelId,
              pixel: pixel
            });
            // Call callback if provided
            if (callback) {
              callback(pixel);
            }
          } catch (e) {
            console.warn(
              '>> Callback to initTiktokTrackingPixel failed.',
              e
            );
          }
        });
    }
  };

  const clearTiktokTrackingPixel = () => setTiktokTrackingPixel(null);

  // GA4 Pixel
  const initGoogleAnalytics = (gaMeasurementId, callback) => {
    if (gaPixel?.pixel && gaPixel?.id === gaMeasurementId) {
      callback(gaPixel.pixel);
      return;
    }

    if (gaMeasurementId) {
      import('react-ga4')
        .then((pixel) => pixel.default)
        .then((pixel) => {
          // Initialize
          pixel.initialize(gaMeasurementId);
          return pixel;
        })
        .then((pixel) => {
          try {
            // Set state
            setGaPixel({
              id: gaMeasurementId,
              pixel: pixel
            });
            // Call callback if provided
            if (callback) {
              callback(pixel);
            }
          } catch (e) {
            console.warn('>> Callback to initGoogleAnalytics failed.', e);
          }
        });
    }
  };

  const clearGaPixel = () => setGaPixel(null);

  // Twitter (X) Pixel
  const initTwitterPixel = (twitterPixelId, callback) => {
    const twitterPixelScriptId = getTwitterPixelScriptId(twitterPixelId);

    // Check if the script is already added to avoid reinitialization
    if (document.getElementById(twitterPixelScriptId)) {
      callback(window.twq);
      return;
    }

    // Initialize Twitter Pixel for the specific product
    !(function (e, t, n, s, u, a) {
      e.twq ||
        ((s = e.twq =
          function () {
            s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
          }),
        (s.version = '1.1'),
        (s.queue = []),
        (u = t.createElement(n)),
        (u.async = !0),
        (u.id = twitterPixelScriptId),
        (u.src = '//static.ads-twitter.com/uwt.js'),
        (a = t.getElementsByTagName(n)[0]),
        a.parentNode.insertBefore(u, a));
    })(window, document, 'script');

    // Initialize the Twitter Pixel
    window.twq('config', twitterPixelId);

    setTwitterPixel({
      id: twitterPixelId,
      pixel: window.twq
    });

    callback(window.twq);
  };

  const clearTwitterPixel = () => {
    const twitterPixelScriptId = getTwitterPixelScriptId(twitterPixel?.id);
    if (twitterPixelScriptId) {
      removeElFromDomById(twitterPixelScriptId);
    }

    setTwitterPixel(null);
  };

  // Linkedin Pixel
  const initLinkedinInsigtsPixel = (linkedinInsigtsPartnerId) => {
    const linkedinScriptId = getLinkedinInsightsPixelScriptId(
      linkedinInsigtsPartnerId
    );

    // Check if the script is already added to avoid reinitialization
    if (document.getElementById(linkedinScriptId)) {
      return;
    }

    // Initialize Twitter Pixel for the specific product
    // Dynamically create and insert the LinkedIn Insights script
    !(function (l) {
      if (!l) {
        window.lintrk = function (a, b) {
          window.lintrk.q.push([a, b]);
        };
        window.lintrk.q = [];
      }
      const s = document.getElementsByTagName('script')[0];
      const b = document.createElement('script');
      b.type = 'text/javascript';
      b.async = true;
      b.id = linkedinScriptId;
      b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
      s.parentNode.insertBefore(b, s);
    })(window.lintrk);

    // Store the partner ID
    window._linkedin_partner_id = linkedinInsigtsPartnerId;
    window._linkedin_data_partner_ids =
      window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(linkedinInsigtsPartnerId);

    // Initialize the linkedin insights pixel
    setLinkedinInsigtsPixel({
      id: linkedinInsigtsPartnerId,
      pixel: window.lintrk
    });
  };

  const clearLinkedinInsigtsPixel = () => {
    const linkedinInsightsPixelScriptId = getLinkedinInsightsPixelScriptId(
      linkedinInsigtsPixel?.id
    );
    if (linkedinInsightsPixelScriptId) {
      removeElFromDomById(linkedinInsightsPixelScriptId);
    }

    setLinkedinInsigtsPixel(null);
  };

  const removeAllPixels = () => {
    clearFbTrackingPixel();
    clearTiktokTrackingPixel();
    clearGaPixel();
    clearTwitterPixel();
    clearLinkedinInsigtsPixel();
  };

  const value = {
    // fb pixel
    fbTrackingPixel,
    initFbTrackingPixel,
    clearFbTrackingPixel,
    // tiktok pixel
    tiktokTrackingPixel,
    initTiktokTrackingPixel,
    clearTiktokTrackingPixel,
    // GA4
    gaPixel,
    initGoogleAnalytics,
    clearGaPixel,
    // Twitter
    twitterPixel,
    initTwitterPixel,
    clearTwitterPixel,
    // Linkedin
    linkedinInsigtsPixel,
    initLinkedinInsigtsPixel,
    clearLinkedinInsigtsPixel,

    removeAllPixels
  };

  return (
    <PixelsContext.Provider value={value}>
      {children}
    </PixelsContext.Provider>
  );
};

// Create the useTrackingPixelCtx hook
const usePixelCtx = () => useContext(PixelsContext);

// Export all three
export { PixelsContext, PixelsContextProvider, usePixelCtx };
