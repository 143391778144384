import { convertQueryStringToObject } from '@/services/helpers/queryString';

import { desktopHeaderHeight } from './commonPageConstants';
import { DOCUMENT_TITLE_TEMPLATE } from './constants';

//The legacy versions of Opera does not allow using `scrollTo` (or it's Opera's bug), so we need to validate `scrollTo`
const callScrollToWithValidation = (callback) => {
  try {
    callback && callback();
  } catch (e) {
    return false;
  }
};

export const scrollToTop = () => {
  callScrollToWithValidation(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  });
};

export const scrollToRef = (ref, negOffset = 0) => {
  callScrollToWithValidation(() => {
    window.scrollTo(0, ref?.current?.offsetTop - negOffset);
  });
};

export const scrollToDom = (element, offset) => {
  callScrollToWithValidation(() => {
    window.scrollTo(0, element?.offsetTop - offset);
  });
};

export const isInViewport = (ref) => {
  try {
    if (!ref.current) return false;
    const rect = ref.current.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  } catch (e) {
    return false;
  }
};

export const isAboveViewportHeight = (ref, extraSpace) => {
  if (!ref.current) return false;

  const rect = ref.current.getBoundingClientRect();

  return (
    rect.bottom + extraSpace >=
    (window.innerHeight || document.documentElement.clientHeight)
  );
};

export const isInViewportHeight = (ref) => {
  try {
    if (!ref.current) return false;

    const rect = ref.current.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight)
    );
  } catch (e) {
    return false;
  }
};

export const updateDocumentTitle = (courseName) => {
  const prefix = courseName ? `${courseName} | ` : '';

  document.title = `${prefix}${DOCUMENT_TITLE_TEMPLATE}`;
};

export const stopPropagation = (e) => {
  e.stopPropagation();
};

export const removeReadonlyAttribute = () => {
  this.removeAttribute('readonly');
};

export const minDesktopWidth = 992;
export const minTabletWidth = 768;
export const fbVideoDesktopHeight = 490;
export const fbVideoMobileHeight = 914;

export const checkIsDesktop = () => window.innerWidth >= minDesktopWidth;
export const checkIsGtEqSm = () => window.innerWidth >= 480;
export const checkIsGtEqLg = () => window.innerWidth >= 992;

export const checkIsTablet = () =>
  window.innerWidth >= minTabletWidth &&
  window.innerWidth < minDesktopWidth;

export const mobileNavHeight = 64;
export const desktopNavHeight = 80;

export const getSectionScrollOffset = (isDesktopView) => {
  const globalNavbarHeight = isDesktopView ? desktopHeaderHeight : 0;
  const sectionNavbarHeight = 56;
  const scrollToSectionOffset = globalNavbarHeight + sectionNavbarHeight;

  return scrollToSectionOffset;
};

export const getQueryHashParams = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  const queryHash = window.location.hash || '';

  if (!queryHash) {
    return {};
  }

  const queryParamsJson = convertQueryStringToObject(queryHash.slice(1)); //cut "#"
  return queryParamsJson;
};

export const getQueryParams = () => {
  const queryStr = window.location.search || '';
  const queryParamsJson = convertQueryStringToObject(queryStr);
  return queryParamsJson;
};

export const removeElFromDomById = (id) => {
  const el = document.getElementById(id);
  if (el) {
    el.remove();
  }
};
