import dynamic from 'next/dynamic';
import { forwardRef, useEffect, useRef } from 'react';

const ToastPortal = dynamic(() => import('./ToastPortal'), { ssr: false });

// eslint-disable-next-line react/display-name
const ForwardRefToastPortal = forwardRef((props, ref) => (
  <ToastPortal {...props} toastRef={ref} />
));

export const DEFAULT_MSG = '';
export const TOAST_TYPE = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFORMATION: 'information',
  NEUTRAL: 'neutral',
  NEUTRAL_LIGHT: 'neutral-light'
};

//this is a global store for toast event listener
class GlobalStore {
  constructor() {
    this.func = [];
  }

  // we store listener function and subscribe them to executes on an event emit
  on(listener) {
    this.func.push(listener);
  }

  //this runs all the listerner functions that are subscribed to global store
  emit(options) {
    this.func.forEach((listener) => listener(options));
  }
}

// creating a reference to the global store
const globalStore = new GlobalStore();

export const showToast = (options) => {
  globalStore.emit({ ...options });
};

export const showNeutralToast = (text) => {
  showToast({ text, nplVersion: 2 });
};

export const showSuccessToast = (text) => {
  showToast({ text, type: 'success', nplVersion: 2 });
};

export const showErrorToast = (error) => {
  //capture the error for logs
  console.error(error);
  showToast({ text: error, type: 'error', nplVersion: 2 });
};

export const removeAllToasts = () => {
  globalStore.emit({ removeAll: true });
};

export const ToastContainer = () => {
  const toastRef = useRef();

  useEffect(() => {
    const addToastListener = (options) => {
      if (options.removeAll) {
        toastRef.current?.removeAllToasts();
        return;
      }

      toastRef.current?.addToast(options);
    };
    globalStore.on(addToastListener);
  }, []);

  return <ForwardRefToastPortal ref={toastRef} />;
};

export default ToastContainer;
